import classes from './EpisodePlayer.module.css';
import React, {useContext, useEffect} from 'react';
import save from '../../../img/save.svg';
import info from '../../../img/bookmark.svg';
import SocialShare from '../../SocialShare/SocialShare';
import Recommended from '../../RecommendedMovie/Recommended';
import {useNavigate, useParams} from 'react-router';
import SeriesEpisodes from '../seriesEpisodes/SeriesEpisodes';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {makeReqest} from '../../../axios';
import Reviews from '../../Reviews/Reviews';
import filledWatchLaterIcon from './../../../img/BookmarkSimple.svg';
import NotificationFilled from './../../../img/notificationFilled.svg';
import axios from 'axios';
import Spinner from '../../spinner/Spinner';
import {useState} from 'react';
import {AuthContext} from '../../../context/authContext';
import classNames from 'classnames';
import {ToastContainer, toast} from 'react-toastify';
import {Modal, ModalBody, ModalFooter, Button} from 'react-bootstrap';
import ReactModal from 'react-modal';


const EpisodePlayer = () => {
    const getUserDetails = async () => {
        const useragent = navigator.userAgent;

        const response = await axios.get('https://ipapi.co/json/');
        const {ip, country_name: country} = response.data;

        return {
            useragent,
            ip,
            country,
        };
    };

    let {id} = useParams();
    const [seriesData, setSeriesData] = useState(null);
    const [videoSrc, setVideoSrc] = useState('');

    const [selectedSeasonIndex, setSelectedSeasonIndex] = useState(0);
    const [selectedEpisodeIndex, setSelectedEpisodeIndex] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [note, setNote] = useState('');
    const currentUser = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const userId = currentUser?.currentUser?.body?.user?.user_id || '';


    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const scrollToTop = () => {
        window.scrollTo({
            top: 0, // Sayfanın en üstüne gitmek için yüksekliği 0 olarak ayarlayın.
            behavior: 'smooth', // Animasyonlu bir kaydırma yapmak için "smooth" kullanabilirsiniz.
        });
    };

    const reportMt = useMutation({
        mutationFn: async () => {
            const userEmail = currentUser?.currentUser?.body.user?.user_email;
            const serieTitle = episode?.data.body.episode.serie.serie_title; // Seri başlığını al
            const episodeTitle = episode?.data.body.episode.episode_title; // Bölüm başlığını al (varsayım, doğru yolu sizin belirlemeniz gerekiyor)
            const userDetails = await getUserDetails();
            const updatedNote = `${note}\n\nIP: ${userDetails.ip}\nUser-agent: ${userDetails.useragent}\nCountry: ${userDetails.country}`;

            const form = new FormData();
            form.append('link', window.location.href);
            form.append('note', updatedNote);
            form.append('usermail', userEmail);
            form.append('serie_title', serieTitle); // Seri başlığını ekle
            form.append('episode_title', episodeTitle); // Bölüm başlığını ekle

            return axios.post(`https://admin.watchturks.online/report`, form);

        },
        onSuccess: async (respnse) => {
            toast.success('Issue report has been sent successfully.', {
                position: 'bottom-left',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            closePopup();
        },
        onError: () => {
            toast.error(
                'An error occurred while sending the issue report.',
                {
                    position: 'bottom-left',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
        }
    });

    const closePopup = () => {
        setShowPopup(false);
        setNote('');
    };
    const {data: episode, error, isLoading} = useQuery({
        queryKey: ['episode', id],
        queryFn: () => {
            return axios.get(`https://admin.watchturks.online/api/episodes`, {
                params: {
                    episodeId: id,
                    userId: userId,
                }
            });
        },
        retry: (error) => {
            navigate('/choosepayment');
            return false;
        },
        onError: (error) => {
            toast.error(error.response.data.message || error.response.data.error, {
                position: 'bottom-left',
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                toastId: 'add-movies-error',
            });
        },
    });

    const handleReportIssue = (e) => {
        e.preventDefault();
        reportMt.mutate()
    }

    const handleNextEpisode = () => {
        const nextEpisodeId = episode.data.body.episode?.next_episode.id;

        navigate(`/watchEpisodes/${nextEpisodeId}`);

    };
    useEffect(() => {
        const videoLink = episode?.data.body.episode.episode_link;

        const checkVideoLink = async (link) => {
            try {
                const response = await axios.get(link, {validateStatus: (status) => status === 200});
                if (response.status === 200) {
                    return link;
                }
            } catch (error) {
            }
            const fallbackLink = link.replace('.com', '.net');
            try {
                const fallbackResponse = await axios.get(fallbackLink, {validateStatus: (status) => status === 200});
                if (fallbackResponse.status === 200) {
                    return fallbackLink;
                }
            } catch (error) {
            }
            return link;
        };

        if (videoLink) {
            checkVideoLink(videoLink).then(updatedLink => {
                setVideoSrc(updatedLink);
            });
        }
    }, [episode]);


    const handlePreviousEpisode = () => {
        const prevEpisodeId = episode.data.body.episode?.previous_episode.id

        navigate(`/watchEpisodes/${prevEpisodeId}`);
    };

    // console.log(selectedEpisodeIndex);
    return (
        <>
            {isLoading ? (
                <Spinner/>
            ) : (
                <div>
                    <div className={classes.moviePlayerTop}>
                        <div className={classes.headerPlayer}>
                            <>
                                <button
                                    className={classNames(classes.prevEpisode, {
                                        'd-none': episode.data.body.episode.previous_episode == null && episode.data.body.episode.is_first_episode_of_season,
                                    })}
                                    onClick={handlePreviousEpisode}
                                >
                                    <span>{episode.data.body.episode.is_first_episode_of_season ? 'Previous season' : 'Previous episode'}</span>
                                </button>
                                <div className={classes.seasonName}>

                                    <div>
                                        <h2>{episode?.data.body.episode.serie.serie_title}</h2>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '10px',
                                            }}
                                        >
                                            {' '}
                                            <p>{episode?.data.body.episode.season.title}</p>
                                            <p>{episode?.data.body.episode.episode_title}</p>
                                        </div>
                                    </div>

                                </div>
                                <button
                                    className={classNames(classes.nextEpisode, {
                                        'd-none': episode.data.body.episode.next_episode == null && episode.data.body.episode.is_season_final
                                    })}
                                    onClick={handleNextEpisode}
                                >
                                    <span>{episode.data.body.episode.is_season_final ? 'Next season' : 'Next episode'}</span>
                                </button>
                            </>
                        </div>
                        <div id="episodeIframe" className={classes.videoPlayer}>
                            {episode && episode.data && episode.data.body && episode.data.body.episode && (
                                <iframe
                                    src={`${videoSrc}?userId=${userId}&video_id=${id}&video_type=episode`}
                                    frameBorder="0"
                                    allowFullScreen
                                    width="100%"
                                    height="450"
                                    title="Episode Player"
                                />
                            )}
                        </div>


                        <div className={classes.headerPlayer2}>
                            <>
                                <button className={classNames(classes.prevEpisodeTopBtn, {
                                    'd-none': episode.data.body.episode.previous_episode == null && episode.data.body.episode.is_first_episode_of_season,
                                })}
                                        onClick={() => {
                                            handlePreviousEpisode();
                                            scrollToTop();
                                        }}>
                                    <span>{episode.data.body.episode.is_first_episode_of_season ? 'Previous season' : 'Previous episode'}</span>
                                </button>
                                <button className={classNames(classes.nextEpisodeTopBtn, {
                                    'd-none': episode.data.body.episode.next_episode == null && episode.data.body.episode.is_season_final
                                })} onClick={() => {
                                    handleNextEpisode();
                                    scrollToTop();
                                }}>
                                    <span>{episode.data.body.episode.is_season_final ? 'Next season' : 'Next episode'}</span>
                                </button>
                            </>
                        </div>
                        <div className={classes.reportIssueContainer} style={{textAlign: 'center'}}>
                            <button className={classes.Reportbutton} onClick={() => setShowPopup(true)}>
                                Report an Issue
                            </button>
                        </div>
                        {showPopup && (
                            <ReactModal style={{
                                overlay: {
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundColor: 'rgba(255, 255, 255, 0.75)'
                                },
                                content: {
                                    position: 'absolute',
                                    top: '40px',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    right: '40px',
                                    bottom: '40px',
                                    width: '300px',
                                    height: '300px',
                                    border: '1px solid #ccc',
                                    background: '#151b25',
                                    overflow: 'auto',
                                    WebkitOverflowScrolling: 'touch',
                                    borderRadius: '4px',
                                    outline: 'none',
                                    padding: '20px',
                                    textAlign: 'center'
                                }
                            }}
                                        isOpen={showPopup}
                                        onRequestClose={closePopup}
                                        portalClassName="custommodal"
                                        overlayClassName="customoverlay"
                            >
                                <form onSubmit={handleReportIssue}>

                                    <ModalBody>
      <textarea
          className="form-control report-input"
          placeholder="Explain the problem..."
          value={note}
          onChange={(e) => setNote(e.target.value)}
          required
      />
                                        <input type="hidden" name="usermail" value={email}/>

                                    </ModalBody>
                                    <ModalFooter>
                                        <button style={{
                                            flexDirection: 'row',
                                            marginTop: '10px',
                                            marginRight: '20px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '260px',
                                            height: '45px',
                                            color: 'white',
                                            background: 'linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%)',
                                            border: 'none',
                                            outline: 'none',
                                            boxShadow: 'none',
                                            cursor: 'pointer',
                                            borderRadius: '12px',
                                        }}
                                                type="submit" className="report-button">
                                            Send Report
                                        </button>
                                        <button
                                            style={{
                                                flexDirection: 'row',
                                                marginTop: '10px',
                                                marginRight: '20px',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '260px',
                                                height: '45px',
                                                color: 'white',
                                                background: 'linear-gradient(to right, #ff4e50, #00274e)',
                                                border: 'none',
                                                outline: 'none',
                                                boxShadow: 'none',
                                                cursor: 'pointer',
                                                borderRadius: '12px',
                                            }}
                                            className="report-button"
                                            onClick={closePopup}
                                        >
                                            Cancel
                                        </button>
                                    </ModalFooter>
                                </form>
                            </ReactModal>

                        )}
                        <SeriesEpisodes
                            canPlay={episode?.data.body.canPlay}
                            seasons={episode?.data.body.episode.seasons}
                            currentSeasonId={episode?.data.body.episode.season_id}
                            fromPlayer={true}
                        />
                        <SocialShare/>
                        <ToastContainer></ToastContainer>

                    </div>
                </div>
            )}
        </>
    );
};

export default EpisodePlayer;

import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {AuthContext} from '../../../context/authContext';
import classes from './LatestEpisode.module.css';
import Button from 'react-clarity/lib/Button/Button';

const scrollToTop = () => {
	window.scrollTo({
		top: 0,
		behavior: 'smooth',
	});
};

const SeriesEpisodes = ({seasons, canPlay, currentSeasonId, fromPlayer}) => {
	const navigate = useNavigate();
	const currentUser = useContext(AuthContext);
	const [searchTerm, setSearchTerm] = useState('');
	const [hasEpisodes, setHasEpisodes] = useState(false);

	const [selectedTabIndex, setSelectedTabIndex] = useState(0);

	useEffect(() => {
		if (fromPlayer) {
			const matchingSeasonIndex = seasons?.findIndex((season) => season.id == currentSeasonId);
			if (matchingSeasonIndex !== -1) {
				setSelectedTabIndex(matchingSeasonIndex);
			}
		}
	}, [fromPlayer, seasons, currentSeasonId]);


	const handleEpisodeClick = (episode) => {
		scrollToTop();
		if (!currentUser.currentUser) {
			navigate('/signin');
		}
		else {
			if (canPlay) {
				navigate(`/watchEpisodes/${episode?.episode_id}`);
			}
			else {
				navigate('/choosepayment');
			}
		}
	};

	useEffect(() => {
		setHasEpisodes(seasons && seasons[0]?.episodes.length > 0)
	}, [seasons]);

	const filterEpisodes = () => {
		return seasons
			?.map((season) => season?.episodes)
			.flat()
			.filter((episode) =>
				episode.episode_title.toLowerCase().includes(searchTerm.toLowerCase())
			);
	};

	return (
		<Tabs   selectedIndex={selectedTabIndex}
		        onSelect={(index) => setSelectedTabIndex(index)}
		        className={`${!hasEpisodes ? 'd-noned' : ''}`}>
			<div className={classes.carousel} style={{textAlign: 'center'}}>
				<h2 style={{fontSize: '24px'}}>
					<div className={classes['search-bar']}>
						<label htmlFor="episodeSearch" style={{fontSize: '14px', marginRight: '8px'}}>Search
							Episode: </label> {/* <-- Label eklendi */}
						<input
							type="text"
							placeholder="Search Episode..."
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
						/>
					</div>
					<TabList>
						{seasons?.map((season) => (
							<Tab key={season.id} id={season.id}>
								<span style={{marginLeft: '10px'}}>{season.title}</span>
							</Tab>
						))}
					</TabList>
				</h2>

				{searchTerm ? (
					<ul className={classes.episodesList}>
						{filterEpisodes().map((episode) => (
							<li
								key={episode.episode_id}
								className={classes.episodeItem}
								onClick={() => handleEpisodeClick(episode)}
							>
								<div className={classes.episodeImage}>
									<img
										src={episode.episode_image}
										alt={episode.episode_title}
										style={{
											width: '150px'
										}}
									/>
								</div>
								<div className={classes.episodeInfo}>
									<h5>{episode.episode_title}</h5>
									<p>{new Date(episode.episode_date).toLocaleDateString()}</p>
								</div>
							</li>
						))}

					</ul>
				) : (
					seasons?.map((season) => (
						<TabPanel key={season.id}>
							<ul className={classes.episodesList}>
								{season.episodes
									.filter((episode) =>
										episode.episode_title.toLowerCase().includes(searchTerm.toLowerCase())
									)
									.map((episode) => (
										<li
											key={episode.episode_id}
											className={classes.episodeItem}
											onClick={() => handleEpisodeClick(episode)}
										>
											<div className={classes.episodeImage}>
												<img
													src={episode.episode_image}
													alt={episode.episode_title}
													style={{}}
												/>
											</div>
											<div className={classes.episodeInfo}>
												<h5>{episode.episode_title}</h5>
												<p>{new Date(episode.episode_date).toLocaleDateString()}</p>
											</div>
											<div>
												<Button className={classes.button} onClick={() => handleEpisodeClick(episode)}
												>
													View Episode
												</Button>
											</div>
										</li>
									))}
							</ul>
						</TabPanel>
					))
				)}
			</div>
		</Tabs>
	);
};

export default SeriesEpisodes;
